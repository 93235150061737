<template>
    <div class="container has-space">
         <MobileHeaderBack :title="'PRIVACY_POLICY'"></MobileHeaderBack>
        <div class="policy-wrapper">
            <h3 v-if="!isMobile" class="policy-title">{{$t('PRIVACY_POLICY')}}</h3>
            <div>
                <p v-html="content" class="hidden"></p>
            </div>
        </div>
    </div>
</template>

<script>
import policy from '@/js/privacy/policy.js'
import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import {mapGetters} from 'vuex'
export default {
    components:{
        MobileHeaderBack
    },

    data() {
        return {
            content:'',
            policyData: policy
        }
    },
    computed: {
        ...mapGetters([
            'currentLocale',
            'isMobile'
        ]),
    },
    watch:{
        currentLocale: {
            deep: true,
            handler(prev, nesw) {
              this.init(); 
            }
        },
    },
    
    mounted() {
       this.init();
    },
    methods: {
        init() {
             this.content = this.policyData[this.currentLocale] ? this.policyData[this.currentLocale] : this.policyData['en'];
        }
    },
}
</script>

  <style scoped>
        .policy-title{
            font-size: 2.125rem;
            color: white;
        }
        .policy-wrapper{
            padding-left: 12rem;
        }
        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c9 {
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            font-weight: 400;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c3 {
            color: white;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c1 {
            color: white;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12px;
            font-family: "Arial";
            font-style: normal
        }

        .c2 {
            margin-left: 22pt;
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c4 {
            margin-left: 45pt;
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c10 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c6 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c8 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c12 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c11 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c0 {
            color: inherit;
            text-decoration: inherit
        }

        .c5 {
            font-weight: 700
        }

        .c7 {
            height: 11pt
        }

        .title {
            padding-top: 0pt;
            color: white;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: white;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: white;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: white;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
         @media (max-width: 768px){
             .policy-wrapper{
                 padding-left: 0;
             }
         }
    </style>